var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"elevation-1",attrs:{"tile":""}},[_c('v-card-text',[_c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'facebook'),"label":_vm.$t('hr.employee.social_media.facebook'),"rules":{
              max: 255,
              regex: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
            },"index":0,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticStyle:{"max-width":"400px"},attrs:{"outlined":"","dense":"","color":"blue darken-4","prepend-inner-icon":"mdi-facebook","counter":"255","error-messages":errors},model:{value:(_vm.employeePayload.facebook),callback:function ($$v) {_vm.$set(_vm.employeePayload, "facebook", $$v)},expression:"employeePayload.facebook"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'linkedin'),"label":_vm.$t('hr.employee.social_media.linkedin'),"rules":{
              max: 255,
              regex: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
            },"index":1,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticStyle:{"max-width":"400px"},attrs:{"outlined":"","dense":"","color":"blue","prepend-inner-icon":"mdi-linkedin","counter":"255","error-messages":errors},model:{value:(_vm.employeePayload.linkedin),callback:function ($$v) {_vm.$set(_vm.employeePayload, "linkedin", $$v)},expression:"employeePayload.linkedin"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'twitter'),"label":_vm.$t('hr.employee.social_media.twitter'),"rules":{
              max: 255,
              regex: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
            },"index":2,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticStyle:{"max-width":"400px"},attrs:{"outlined":"","dense":"","color":"blue lighten-1","prepend-inner-icon":"mdi-twitter","counter":"255","error-messages":errors},model:{value:(_vm.employeePayload.twitter),callback:function ($$v) {_vm.$set(_vm.employeePayload, "twitter", $$v)},expression:"employeePayload.twitter"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'instagram'),"label":_vm.$t('hr.employee.social_media.instagram'),"rules":{
              max: 255,
              regex: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
            },"index":3,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticStyle:{"max-width":"400px"},attrs:{"outlined":"","dense":"","color":"#bc2a8d","prepend-inner-icon":"mdi-instagram","counter":"255","error-messages":errors},model:{value:(_vm.employeePayload.instagram),callback:function ($$v) {_vm.$set(_vm.employeePayload, "instagram", $$v)},expression:"employeePayload.instagram"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":_vm.$helpers.get(_vm.employee, 'skype'),"label":_vm.$t('hr.employee.social_media.skype'),"rules":{
              max: 255,
              regex: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
            },"index":4,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticStyle:{"max-width":"400px"},attrs:{"outlined":"","dense":"","color":"blue darken-1","prepend-inner-icon":"mdi-skype","counter":"255","error-messages":errors},model:{value:(_vm.employeePayload.skype),callback:function ($$v) {_vm.$set(_vm.employeePayload, "skype", $$v)},expression:"employeePayload.skype"}})]}}],null,true)})],1),_vm._l((_vm.employeePayload.objectAttributeValues),function(objectAttributeValue,idx){return [_c('v-col',{key:idx,staticClass:"py-0",attrs:{"cols":"12"}},[_c('profile-field',{attrs:{"value":objectAttributeValue.value,"label":objectAttributeValue.name,"index":5 + idx,"active-index":_vm.activeIndex,"saving":_vm.saving,"loading":_vm.loading,"is-editable":_vm.isEditable},on:{"click:edit":_vm.onEditClick,"click:save":function($event){return handleSubmit(_vm.onSaveClick)},"click:cancel":_vm.onCancelClick},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticStyle:{"max-width":"400px"},attrs:{"outlined":"","dense":"","color":"tertiary","error-messages":errors},model:{value:(objectAttributeValue.value),callback:function ($$v) {_vm.$set(objectAttributeValue, "value", $$v)},expression:"objectAttributeValue.value"}})]}}],null,true)})],1)]})],2)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }